import { Table } from '@mui/material';
import { ChangeEvent, FC, useEffect, useId, useMemo, useState } from 'react';
import { TableWrap } from './styled';
import { MemoizedTableBody } from './table-body/component';
import { MemoizedTableHeader } from './table-header/component';
import { CustomTablePagination } from './table-pagination/component';
import { ICustomTableProps } from './types';

export const CustomTable: FC<ICustomTableProps> = ({
	data,
	loading = false,
	pagination = false,
	searchTable = false,
	customPagination,
	isNarrow = false,
}) => {
	const tableId = useId();
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [page, setPage] = useState(0);

	const memoizedRows = useMemo(() => {
		// Extract the relevant slice of data rows
		const slicedDataRows =
			data.rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ||
			[];

		const emptyRowsCount = rowsPerPage - slicedDataRows.length;
		const emptyRows = Array.from({ length: emptyRowsCount }, () => ({}));

		// Concatenate the data rows with the empty rows
		return data.rows?.length > 0
			? slicedDataRows.concat(emptyRows)
			: slicedDataRows;
	}, [data.rows, rowsPerPage, page]);

	const handleChangeRowsPerPage = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	): void => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	useEffect(() => {
		loading && setPage(0);
	}, [loading]);

	return (
		<TableWrap>
			<Table style={{ tableLayout: 'fixed', width: '100%' }}>
				<MemoizedTableHeader
					tableId={tableId}
					columns={data.columns}
					searchEnabled={searchTable}
				/>
				<MemoizedTableBody
					tableId={tableId}
					rows={memoizedRows}
					columns={data.columns}
					loading={loading}
					isNarrow={isNarrow}
				/>
			</Table>
			{pagination && (
				<CustomTablePagination
					totalRows={data.rows?.length || 0}
					rowsPerPage={rowsPerPage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					page={page}
					handleChangePage={(
						_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
						newPage: number,
					) => setPage(newPage)}
					customComponent={customPagination}
				/>
			)}
		</TableWrap>
	);
};

import { AxiosError } from 'axios';
import { del, get, stateSetter } from '@/utils';
import { IAppUser } from './types';

async function fetchAppUsers(
	page: number, items: number,
	onResponse?: stateSetter<number>
): Promise<IAppUser[]> {
	try {
		const res = await get(`api/protected/users?page=${page + 1}&items=${items}`);
		onResponse && onResponse(res.data?.pages);
		const data = res.data as unknown as { users: IAppUser[] };
		return data?.users || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function fetchQueryUsers(
	query: string, page: number, items: number,
	onResponse?: stateSetter<number>
): Promise<IAppUser[]> {
	try {
		const res = await get(`api/protected/users${query}&page=${page + 1}&items=${items}`);
		onResponse && onResponse(res.data?.pages);
		const data = res.data as unknown as { users: IAppUser[] };
		return data?.users || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function fetchSingleAppUser(userId: string): Promise<IAppUser> {
	try {
		const res = await get(`api/protected/users/${userId}`);
		const data = res.data as unknown as { user: IAppUser };
		return data?.user || {};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm utilizatorul.');
	}
}

async function deleteUser(userId: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/users/${userId}`);
		return res?.status;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să ștergem utilizatorul.');
	}
}

export { fetchAppUsers, fetchSingleAppUser, deleteUser, fetchQueryUsers };
import { AxiosError } from 'axios';
import { del, get, post, edit, stateSetter } from '@/utils';
import { ICreateFSP, IFSP, IUpdateFSP } from './types';

async function fetchFSPs(
	getAll: boolean, page: number, items: number,
	onResponse?: stateSetter<number>
): Promise<IFSP[]> {
	try {
		const pageVariable = getAll ? 'all' : page + 1;
		const res = await get(`api/protected/fsps?page=${pageVariable}&items=${items}`);
		onResponse && onResponse(res.data?.pages);
		const data = res.data as unknown as { fsps: IFSP[] };
		return data?.fsps || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function fetchQueryFSPs(
	getAll: boolean,
	query: string, page: number, items: number,
	onResponse?: stateSetter<number>
): Promise<IFSP[]> {
	try {
		const pageVariable = getAll ? 'all' : page + 1;
		const res = await get(`api/protected/fsps${query}&page=${pageVariable}&items=${items}`);
		onResponse && onResponse(res.data?.pages);
		const data = res.data as unknown as { fsps: IFSP[] };
		return data?.fsps || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function fetchSingleFSP(moderatroId: string): Promise<IFSP> {
	try {
		const res = await get(`api/protected/fsps/${moderatroId}`);
		const data = res.data as unknown as { fsp: IFSP };
		return data?.fsp || {};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm administrația.');
	}
}

async function deleteFSP(moderatroId: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/fsps/${moderatroId}`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să ștergem administrația.');
	}
}

async function postFSP(FSPObj: ICreateFSP): Promise<string | number> {
	try {
		const res = await post('api/protected/fsps', { ...FSPObj });
		return res?.status === 201 ? 201 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să adăugăm administrația.');
	}
}

async function patchFSP(FSPId: string, updatedFSP: IUpdateFSP): Promise<string | number> {
	try {
		const res = await edit(`api/protected/fsps/${FSPId}`, { ...updatedFSP });
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm administrația.');
	}
}

export { fetchFSPs, fetchSingleFSP, deleteFSP, postFSP, patchFSP, fetchQueryFSPs };

import React, { FC } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ro';
import { FilterWrapper } from './styled';
import { FilterProps } from './types';
import { Flex } from '../flex';

export const FilterBox: FC<FilterProps> = ({ 
	filters, 
	setFilters, 
	selectOptions, 
	showSelectFilter = true, 
	children,
	datePlaceholder = 'Select a date',
	selectLabel = 'Select option',
	showSecondSelectFilter = false,
	selectSecondLabel = 'Select option',
	selectSecondOptions
}) => {
	const handleDateChange = (newDate: dayjs.Dayjs | null): void => {
		setFilters({ ...filters, date: newDate?.format('YYYY-MM-DD') || '' });
	};

	const handleSelectChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: 'selectValue' | 'secondSelectValue'
	): void => {
		setFilters({ ...filters, [key]: event.target.value });
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ro'>
			<FilterWrapper>
				<Flex gap={3}>
					<DatePicker
						label={datePlaceholder}
						value={filters.date ? dayjs(filters.date) : null}
						onChange={handleDateChange}
						sx={{ width: '320px'}}
						slotProps={{
							textField: {
								size: 'small',
							}
						}}
					/>
					{showSelectFilter && selectOptions && (
						<TextField
							select
							label={selectLabel}
							value={filters.selectValue || ''}
							onChange={(e) => handleSelectChange(e, 'selectValue')}
							size='small'
							sx={{ width: '240px' }}
							variant='standard'
						>
							{selectOptions.map(option => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					)}
					{showSecondSelectFilter && selectSecondOptions && (
						<TextField
							select
							label={selectSecondLabel}
							value={filters.secondSelectValue || ''}
							onChange={(e) => handleSelectChange(e, 'secondSelectValue')}
							size='small'
							sx={{ width: '240px' }}
							variant='standard'
						>
							{selectSecondOptions.map(option => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					)}
				</Flex>
				{children}
			</FilterWrapper>
		</LocalizationProvider>
	);
};

FilterBox.defaultProps = {
	children: undefined,
};

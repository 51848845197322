import { useCallback, useEffect, useState } from 'react';
import { deleteAdmin, fetchAdmins, fetchQueryAdmins, fetchSingleAdmin, patchAdmin, postAdmin } from './actions';
import { AdminsReturnType, IAdmins, ICreateAdmin, IUpdateAdmin } from './types';
import { notify, stateSetter } from '@/utils';

interface ILoading {
	all: boolean;
	delete: boolean;
	create: boolean;
	update: boolean;
}

export function useAdmins<T extends IAdmins | IAdmins[]>(
	getAdminsOnRender: boolean | undefined = undefined,
	appAdminId: string | undefined = undefined,
	queryParam: string | undefined = undefined
): AdminsReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<ILoading>({ all: true, delete: false, create: false, update: false });
	const [enableQuery, setEnableQuery] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [items, setItems] = useState<number>(5);
	const [totalPages, setTotalPages] = useState<number>(1);

	const toggleLoading = (type: 'all' | 'delete' | 'create' | 'update', value: boolean): void => {
		setLoading((prevState) => ({
			...prevState,
			[type]: value,
		}));
	};

	const getAdmins = useCallback(
		async (pageParam: number, itemsParam: number) => {
			try {
				toggleLoading('all', true);
				const response = await fetchAdmins(pageParam, itemsParam, setTotalPages);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('all', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getQueryAdmins = useCallback(
		async (query: string, queryPage: number, queryItems: number) => {
			try {
				!enableQuery && setEnableQuery(true);
				toggleLoading('all', true);
				const response = await fetchQueryAdmins(query, queryPage, queryItems, setTotalPages);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('all', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getAdmin = useCallback(
		async (adminId: string) => {
			try {
				toggleLoading('all', true);
				const response = await fetchSingleAdmin(adminId);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('all', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeAdmin = useCallback(
		async (adminId: string, onSuccess?: () => void) => {
			try {
				toggleLoading('delete', true);
				const response = await deleteAdmin(adminId);
				if (response === 200) {
					setData(currentData => {
						if (Array.isArray(currentData)) {
							return currentData.filter(admin => admin._id !== adminId) as T;
						}
						return currentData;
					});
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să ștergem administratorul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('delete', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const createAdmin = useCallback(
		async (adminObj: ICreateAdmin, onSuccess?: () => void) => {
			try {
				toggleLoading('create', true);
				const response = await postAdmin(adminObj);
				if (response === 201) {
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să adăugăm administratorul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('create', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const editAdmin = useCallback(
		async (adminId: string, updatedObject: IUpdateAdmin, onSuccess?: () => void) => {
			try {
				toggleLoading('update', true);
				const response = await patchAdmin(adminId, updatedObject);
				if (response === 200) {
					onSuccess && onSuccess();
				} else if (response === 400) {
					notify.error('Nu am reușit să actualizăm administratorul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('update', false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (getAdminsOnRender && queryParam === undefined) {
			getAdmins(page, items);
		}
		// eslint-disable-next-line
	}, [getAdminsOnRender, queryParam, page, items]);

	useEffect(() => {
		if (appAdminId) {
			getAdmin(appAdminId);
		}
		// eslint-disable-next-line
	}, [appAdminId]);

	useEffect(() => {
		if (queryParam && appAdminId === undefined) {
			getQueryAdmins(queryParam, page, items);
		} else if (appAdminId === undefined && enableQuery) {
			getAdmins(page, items);
		}
		// eslint-disable-next-line
	}, [queryParam, appAdminId, queryParam, page, items]);

	return {
		data: data || ([] as IAdmins[] as T),
		setData: setData as stateSetter<T>,
		loading: loading.all,
		loadingDelete: loading.delete,
		loadingCreate: loading.create,
		loadingUpdate: loading.update,
		getAdmins,
		getAdmin,
		removeAdmin,
		createAdmin,
		editAdmin,
		items,
		page,
		setItems,
		setPage,
		totalPages
	};
}
import { AxiosError } from 'axios';
import { del, get, post, edit, stateSetter } from '@/utils';
import { ICreateModerator, IModerator, IUpdateModerator } from './types';

async function fetchModerators(
	page: number, items: number,
	onResponse?: stateSetter<number>
): Promise<IModerator[]> {
	try {
		const res = await get(`api/protected/moderators?page=${page + 1}&items=${items}`);
		onResponse && onResponse(res.data?.pages);
		const data = res.data as unknown as { moderators: IModerator[] };
		return data?.moderators || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function fetchQueryModerators(
	query: string, page: number, items: number,
	onResponse?: stateSetter<number>
): Promise<IModerator[]> {
	try {
		const res = await get(`api/protected/moderators${query}&page=${page + 1}&items=${items}`);
		onResponse && onResponse(res.data?.pages);
		const data = res.data as unknown as { moderators: IModerator[] };
		return data?.moderators || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
}

async function fetchSingleModerator(moderatorId: string): Promise<IModerator> {
	try {
		const res = await get(`api/protected/moderators/${moderatorId}`);
		const data = res.data as unknown as { moderator: IModerator };
		return data?.moderator || {};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm moderatorul.');
	}
}

async function deleteModerator(moderatorId: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/moderators/${moderatorId}`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să ștergem moderatorul.');
	}
}

async function postModerator(moderatorObj: ICreateModerator): Promise<string | number> {
	try {
		const res = await post('api/protected/moderators', { ...moderatorObj });
		return res?.status === 201 ? 201 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să adăugăm moderatorul.');
	}
}

async function patchModerator(moderatorId: string, updatedModerator: IUpdateModerator): Promise<string | number> {
	try {
		const res = await edit(`api/protected/moderators/${moderatorId}`, { ...updatedModerator });
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm moderatorul.');
	}
}

async function patchUnnasigned(moderatorId: string, fspId: string): Promise<string | number> {
	try {
		const res = await edit('api/protected/moderators-fsps-unassign', { fsp: fspId, moderator: moderatorId });
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa disociem administrația.');
	}
}

export {
	fetchModerators, fetchSingleModerator, deleteModerator,
	postModerator, patchModerator, patchUnnasigned, fetchQueryModerators
};

import { FC, lazy, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AuthRoute } from './auth';
import { AdminRoute } from './admin';
import { Loadable } from '../components';
import { useAuthContext } from '@/providers';
import { AuthState } from '@/providers/auth';

// AUTH ROUTES
const NotFound = Loadable(lazy(() => import('../views/common/404-page')));

// AUTH ROUTES
const AuthPage = Loadable(lazy(() => import('../views/auth')));

// COMMON ROUTES
const DashboardPage = Loadable(lazy(() => import('../views/common/dashboard')));
// CHAT PAGE
const ChatPage = Loadable(lazy(() => import('../views/common/chat')));
// PROJECTS
const AllProjectsPage = Loadable(lazy(() => import('../views/common/project/all-projects-page')));
const CreateProjectPage = Loadable(lazy(() => import('../views/common/project/create-project')));
// USERS
const AllUsersPage = Loadable(lazy(() => import('../views/common/users/app-users/all-users')));
const UserProfilePage = Loadable(lazy(() => import('../views/common/users/app-users/user-info')));
// ADMIN
const AllAdminsPage = Loadable(lazy(() => import('../views/common/users/admin-users/all-admins')));
const AdminInfoPage = Loadable(lazy(() => import('../views/common/users/admin-users/admin-info')));
const CreateAdminPage = Loadable(lazy(() => import('../views/common/users/admin-users/create-admin')));
const EditAdminPage = Loadable(lazy(() => import('../views/common/users/admin-users/edit-admin')));
// MODERATOR
const AllModeratorsPage = Loadable(lazy(() => import('../views/common/users/moderator-users/all-moderators')));
const ModeratorProfilePage = Loadable(lazy(() => import('../views/common/users/moderator-users/moderator-info')));
const CreateModeratorPage = Loadable(lazy(() => import('../views/common/users/moderator-users/create-moderator')));
const EditModeratorPage = Loadable(lazy(() => import('../views/common/users/moderator-users/edit-moderator')));
// FSP
const AllFspsPage = Loadable(lazy(() => import('../views/common/users/fsp-users/all-fsp')));
const FspInfoPage = Loadable(lazy(() => import('../views/common/users/fsp-users/fsp-info')));
const CreateFSPPage = Loadable(lazy(() => import('../views/common/users/fsp-users/create-fsp')));
const EditFSPPage = Loadable(lazy(() => import('../views/common/users/fsp-users/edit-fsp')));
// REQUESTS
const ModeratorRequestsPage = Loadable(lazy(() => import('../views/common/requests/moderator-requests')));
const FspRequestsPage = Loadable(lazy(() => import('../views/common/requests/fsp-requests')));
const FspInfoRequestPage = Loadable(lazy(() => import('../views/common/requests/fsp-info-request')));
const ModeratorInfoRequestPage = Loadable(lazy(() => import('../views/common/requests/moderator-info-page')));
// ANNOUNCEMENTS
const AnnouncmentPage = Loadable(lazy(() => import('../views/common/announcment/all')));
const CreateAnnouncementPage = Loadable(lazy(() => import('../views/common/announcment/create')));
const EditAnnouncementPage = Loadable(lazy(() => import('../views/common/announcment/edit')));
const AnnouncementDetailsPage = Loadable(lazy(() => import('../views/common/announcment/details')));
// CONSULTATIONS
const ConsultationPage = Loadable(lazy(() => import('../views/common/consultation/all')));
const ConsultationDetails = Loadable(lazy(() => import('../views/common/consultation/details')));
const CreateConsultationPage = Loadable(lazy(() => import('../views/common/consultation/create')));
const EditConsultationPage = Loadable(lazy(() => import('../views/common/consultation/edit-survey')));

// AGREMENTS
const AllPP = Loadable(lazy(() => import('../views/common/clause/pp/all')));
const CreatePP = Loadable(lazy(() => import('../views/common/clause/pp/create')));
const DetailsPP = Loadable(lazy(() => import('../views/common/clause/pp/details')));
const EditPP = Loadable(lazy(() => import('../views/common/clause/pp/edit')));

const AllTC = Loadable(lazy(() => import('../views/common/clause/tc/all')));
const CreateTC = Loadable(lazy(() => import('../views/common/clause/tc/create')));
const DetailsTC = Loadable(lazy(() => import('../views/common/clause/tc/details')));
const EditTC = Loadable(lazy(() => import('../views/common/clause/tc/edit')));

const AllGDPR = Loadable(lazy(() => import('../views/common/clause/gdpr/all')));
const CreateGDPR = Loadable(lazy(() => import('../views/common/clause/gdpr/create')));
const DetailsGDPR = Loadable(lazy(() => import('../views/common/clause/gdpr/details')));
const EditGDPR = Loadable(lazy(() => import('../views/common/clause/gdpr/edit')));

const TypesPage = Loadable(lazy(() => import('../views/common/project-types')));

// Profile page
const ProfilePage = Loadable(lazy(() => import('../views/common/profile')));

const InitialRedirect: FC = () => {
	const { user } = useAuthContext();
	const navigate = useNavigate();
	useEffect(() => {
		const savedPath = localStorage.getItem('currentPath');

		if (user?.role) {
			if (savedPath && savedPath !== '/') {
				navigate(savedPath, { replace: true });
			} else {
				navigate('/common/dashboard', { replace: true });
			}
		} else {
			navigate('/auth', { replace: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, navigate]);

	return null;
};

export const RoutesContainer: FC = () => {
	const { refreshAuthState } = useAuthContext();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const { pathname, search } = location;
		if (!pathname.startsWith('/auth')) {
			localStorage.setItem('currentPath', `${pathname}${search}`);
		}
	}, [location]);

	useEffect(() => {
		const handleUnauthenticated = (): void => {
			refreshAuthState(AuthState.SignedOut);
			localStorage.removeItem('currentPath');
			navigate('/auth', { replace: true });
		};

		window.addEventListener('unauthenticated', handleUnauthenticated);

		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		return () => {
			window.removeEventListener('unauthenticated', handleUnauthenticated);
		};
	}, [refreshAuthState, navigate]);

	useEffect(() => {
		const handleUnauthenticated = (): void => {
			refreshAuthState(AuthState.SignedOut);
		};
		window.addEventListener('unauthenticated', handleUnauthenticated);
		// eslint-disable-next-line
    return () => {
			window.removeEventListener('unauthenticated', handleUnauthenticated);
		};
	}, [refreshAuthState]);

	return (
		<Routes>
			<Route path="/" element={<InitialRedirect />} />
			<Route path="/" element={<AuthRoute redirectPath="/common/dashboard" />}>
				<Route path="/auth" element={<AuthPage />} />
			</Route>
			<Route path="/common" element={<AdminRoute redirectPath="/" />}>
				<Route path="/common/dashboard" element={<DashboardPage />} />
				{/* CONSULTATIONS */}
				<Route path="/common/consultation" element={<ConsultationPage />} />
				<Route path="/common/consultation/:id" element={<ConsultationDetails />} />
				<Route path="/common/consultation/create" element={<CreateConsultationPage />} />
				<Route path="/common/consultation/edit/:id" element={<EditConsultationPage />} />
				{/* CHAT */}
				<Route path="/common/chat" element={<ChatPage />} />
				{/* PROJECTS */}
				<Route path="/common/project" element={<AllProjectsPage />} />
				<Route path="/common/project/create" element={<CreateProjectPage />} />
				{/* USERS */}
				<Route path="/common/users-app" element={<AllUsersPage />} />
				<Route path="/common/users-app/:id" element={<UserProfilePage />} />
				{/* ADMINS */}
				<Route path="/common/users-admin" element={<AllAdminsPage />} />
				<Route path="/common/users-admin/:id" element={<AdminInfoPage />} />
				<Route path="/common/users-admin/create" element={<CreateAdminPage />} />
				<Route path="/common/users-admin/edit/:id" element={<EditAdminPage />} />
				{/* MODERATOR */}
				<Route path="/common/users-moderator" element={<AllModeratorsPage />} />
				<Route path="/common/users-moderator/:id" element={<ModeratorProfilePage />} />
				<Route path="/common/users-moderator/create" element={<CreateModeratorPage />} />
				<Route path="/common/users-moderator/edit/:id" element={<EditModeratorPage />} />
				{/* FSP */}
				<Route path="/common/users-fsp" element={<AllFspsPage />} />
				<Route path="/common/users-fsp/:id" element={<FspInfoPage />} />
				<Route path="/common/users-fsp/create" element={<CreateFSPPage />} />
				<Route path="/common/users-fsp/edit/:id" element={<EditFSPPage />} />
				{/* REQUESTS */}
				<Route path="/common/requests/moderator" element={<ModeratorRequestsPage />} />
				<Route path="/common/requests/fsp" element={<FspRequestsPage />} />
				<Route path="/common/requests/fsp/:id" element={<FspInfoRequestPage />} />
				<Route path="/common/requests/moderator/:id" element={<ModeratorInfoRequestPage />} />
				{/* ANNOUNCEMENTS */}
				<Route path="/common/announcment" element={<AnnouncmentPage />} />
				<Route path="/common/announcment/:id" element={<AnnouncementDetailsPage />} />
				<Route path="/common/announcment/create" element={<CreateAnnouncementPage />} />
				<Route path="/common/announcment/edit/:id" element={<EditAnnouncementPage />} />
				{/* AGREMENTS */}
				<Route path="/common/clause/pp" element={<AllPP />} />
				<Route path="/common/clause/pp/create" element={<CreatePP />} />
				<Route path="/common/clause/pp/:id" element={<DetailsPP />} />
				<Route path="/common/clause/pp/edit/:id" element={<EditPP />} />

				<Route path="/common/clause/tc" element={<AllTC />} />
				<Route path="/common/clause/tc/create" element={<CreateTC />} />
				<Route path="/common/clause/tc/:id" element={<DetailsTC />} />
				<Route path="/common/clause/tc/edit/:id" element={<EditTC />} />

				<Route path="/common/clause/gdpr" element={<AllGDPR />} />
				<Route path="/common/clause/gdpr/create" element={<CreateGDPR />} />
				<Route path="/common/clause/gdpr/:id" element={<DetailsGDPR />} />
				<Route path="/common/clause/gdpr/edit/:id" element={<EditGDPR />} />

				<Route path="/common/types" element={<TypesPage />} />

				{/* Profile page */}
				<Route path="/common/profile" element={<ProfilePage />} />
			</Route>
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};

import { useCallback, useEffect, useState } from 'react';
import { notify, stateSetter } from '@/utils';
import { IProjectType, ProjectTypesReturnType } from './types';
import { deleteType, fetchTypes, patchTypes, postTypes } from './actions';

interface ILoading {
    all: boolean;
    delete: boolean;
    create: boolean;
    update: boolean;
}

export function useProjectTypes<T extends IProjectType | IProjectType[]>(
	getTypesOnRender: boolean | undefined = undefined,
): ProjectTypesReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<ILoading>({ all: true, delete: false, create: false, update: false});

	const toggleLoading = (type: 'all' | 'delete' | 'create' | 'update', value: boolean): void => {
		setLoading((prevState) => ({
			...prevState,
			[type]: value,
		}));
	};

	const getProjectTypes = useCallback(
		async () => {
			try {
				toggleLoading('all',true);
				const response = await fetchTypes();
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('all',false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeProjectTypes = useCallback(
		async (typeId: string, onSuccess?: () => void) => {
			try {
				toggleLoading('delete',true);
				const response = await deleteType(typeId);
				if(response === 200) {
					setData(currentData => {
						if (Array.isArray(currentData)) {
							return currentData.filter(FSP => FSP._id !== typeId) as T;
						}
						return currentData;
					});
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reușit să ștergem tipul de proiect!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('delete',false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const createProjectTypes = useCallback(
		async (typeObj: {name: string}, onSuccess?: () => void) => {
			try {
				toggleLoading('create',true);
				const response = await postTypes(typeObj);
				if(response === 201) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reușit să adăugăm tipul de proiect!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('create',false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const editProjectTypes = useCallback(
		async (typeId: string, updatedObject: {name: string}, onSuccess?: () => void) => {
			try {
				toggleLoading('update',true);
				const response = await patchTypes(typeId, updatedObject);
				if(response === 200) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reușit să actualizăm tipul de proiect!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('update',false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (getTypesOnRender) {
			getProjectTypes();
		}
		// eslint-disable-next-line
	}, [getTypesOnRender]);

	return {
		data: data || ([] as IProjectType[] as T),
		setData: setData as stateSetter<T>,
		loading: loading.all,
		loadingDelete: loading.delete,
		loadingCreate: loading.create,
		loadingUpdate: loading.update,
		createProjectTypes,
		editProjectTypes,
		getProjectTypes,
		removeProjectTypes
	};
}